import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import styles from '../styles/Boosters.module.css';
import useSocket from '../hooks/useSocket';
import Buttons from './Buttons';
import TabNavigation from './TabNavigation';

const Boosters = () => {
  const { userId } = useParams();
  const {
    state: { data: user },
    dispatch,
  } = useUser();

  const [usageCounts, setUsageCounts] = useState({});
  const [cooldowns, setCooldowns] = useState({});
  const [appliedBoosters, setAppliedBoosters] = useState([]);
  const [nextUpgradeCosts, setNextUpgradeCosts] = useState({});

  const { socket } = useSocket();

  const energyLevel = user.energy_level || 0;
  const energyRecoveryLevel = user.energy_recovery_level || 0;
  const maxEnergy = user.max_energy || 1000;
  const upgradeCost = 15 * Math.pow(2, energyLevel);

  useEffect(() => {
    const fetchUserBoosters = async () => {
      try {
        const { data } = await axios.get(`https://smetanavv.fvds.ru/user/${userId}`);
        const userBoosters = data.userBoosters || [];

        const counts = {};
        const cooldownsData = {};
        const costs = {};

        userBoosters.forEach((ub) => {
          counts[ub.booster_id] = ub.usage_count;
          if (ub.cooldown_end) {
            cooldownsData[ub.booster_id] = new Date(ub.cooldown_end).getTime();
          }

          // Расчет стоимости следующей прокачки для energy_recovery
          if (ub.booster_id === 'energy_recovery') {
            const baseCost = 30;
            costs[ub.booster_id] = baseCost * Math.pow(2, ub.usage_count);
          }
        });

        setUsageCounts(counts);
        setCooldowns(cooldownsData);
        setAppliedBoosters(userBoosters.map((ub) => ub.booster_id));
        setNextUpgradeCosts(costs);
      } catch (error) {
        console.error('Ошибка при получении бустеров пользователя:', error);
      }
    };

    if (userId) {
      fetchUserBoosters();
    }
  }, [userId]);

  useEffect(() => {
    if (!socket) return;
    socket.on('userUpdated', (updatedUser) => {
      dispatch({ type: 'SET_USER', payload: updatedUser });
      // Дополнительно обновляем cooldowns
      const newCooldowns = {};
      updatedUser.userBoosters.forEach((ub) => {
        if (ub.cooldown_end) {
          newCooldowns[ub.booster_id] = new Date(ub.cooldown_end).getTime();
        }
      });
      setCooldowns(newCooldowns);
    });
    return () => {
      socket.off('userUpdated');
    };
  }, [socket, dispatch]);

  const handleApplyBooster = async (boosterId) => {
    try {
      const response = await axios.post('https://smetanavv.fvds.ru/api/boosters/apply', {
        telegramId: userId,
        boosterId,
      });
      const data = response.data;

      if (data.success) {
        // Обновляем usageCounts и cooldowns
        setUsageCounts((prev) => ({
          ...prev,
          [boosterId]: data.usageCount || prev[boosterId],
        }));
        if (data.cooldown) {
          setCooldowns((prev) => ({
            ...prev,
            [boosterId]: Date.now() + data.cooldown * 1000,
          }));
        }

        // Обновляем стоимость следующей прокачки
        if (boosterId === 'energy_recovery') {
          setNextUpgradeCosts((prev) => ({
            ...prev,
            [boosterId]: 30 * Math.pow(2, data.usageCount),
          }));
        }

        // Обновляем данные пользователя
        const updatedUser = await axios.get(`https://smetanavv.fvds.ru/user/${userId}`);
        dispatch({ type: 'SET_USER', payload: updatedUser.data });
      } else {
        console.error('Ошибка при применении бустера:', data.message);
      }
    } catch (error) {
      console.error(
        'Ошибка при применении бустера:',
        error.response?.data?.message || error.message
      );
    }
  };

  const getTimeRemaining = (timestamp) => {
    const now = Date.now();
    const distance = timestamp - now;
    if (distance <= 0) return null;
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return `${hours}ч ${minutes}м ${seconds}с`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const newCooldowns = { ...cooldowns };
      let updated = false;
      Object.keys(newCooldowns).forEach((boosterId) => {
        if (Date.now() >= newCooldowns[boosterId]) {
          delete newCooldowns[boosterId];
          updated = true;
        }
      });
      if (updated) {
        setCooldowns(newCooldowns);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [cooldowns]);

  return (
    <div>
      <Buttons activePage="Boosters" onButtonClick={() => {}} />
      <TabNavigation />
      <div className={styles.boostersContainer}>
        <h2 className={styles.title}>Покупай улучшения и увеличивай свой доход</h2>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Бесплатные ежедневные усилители</h3>
          <div className={styles.boosterCard}>
            <div className={styles.boosterImageContainer}>
              <div className={styles.boosterImage}>
                <svg width="50" height="50" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="10" fill="#ffbf00" />
                  <path d="M12 8L16 12L12 16" stroke="white" strokeWidth="2" />
                </svg>
              </div>
            </div>
            <div className={styles.boosterDetailsContainer}>
              <p className={styles.boosterName}>Полный запас энергии</p>
              <p className={styles.boosterProgress}>
                {Math.max(0, 5 - (usageCounts['restore'] || 0))} из 5
              </p>
            </div>
            <div className={styles.boosterButtonContainer}>
              <button
                className={styles.applyButton}
                onClick={() => handleApplyBooster('restore')}
                disabled={
                  (cooldowns['restore'] && Date.now() < cooldowns['restore']) ||
                  (usageCounts['restore'] || 0) >= 5
                }
              >
                {cooldowns['restore'] && Date.now() < cooldowns['restore']
                  ? getTimeRemaining(cooldowns['restore'])
                  : 'Применить'}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Усилители</h3>
          <div className={styles.boosterCard}>
            <div className={styles.boosterImageContainer}>
              <div className={styles.boosterImage}>
                <svg width="70" height="70" viewBox="0 0 24 24" fill="none">
                  <rect x="6" y="4" width="12" height="16" rx="2" fill="#00ff00" />
                  <circle cx="12" cy="16" r="2" fill="white" />
                </svg>
              </div>
            </div>
            <div className={styles.boosterDetailsContainer}>
              <p className={styles.boosterName}>Запас энергии</p>
              <p className={styles.boosterLevel}>{energyLevel} ур.</p>
              <p className={styles.boosterEffect}>Макс энергия: {maxEnergy}</p>
            </div>
            <div className={styles.boosterButtonContainer}>
              <button
                className={styles.applyButton}
                onClick={() => handleApplyBooster('increase_energy')}
                disabled={energyLevel >= 10 || user.coins < upgradeCost}
              >
                {upgradeCost} монет
              </button>
            </div>
          </div>
          <div className={styles.boosterCard}>
            <div className={styles.boosterImageContainer}>
              <div className={styles.boosterImage}>
                <svg width="70" height="70" viewBox="0 0 24 24" fill="none">
                  <rect x="6" y="4" width="12" height="16" rx="2" fill="#7fff00" />
                  <path d="M12 8V16" stroke="white" strokeWidth="2" />
                  <path d="M8 12H16" stroke="white" strokeWidth="2" />
                </svg>
              </div>
            </div>
            <div className={styles.boosterDetailsContainer}>
              <p className={styles.boosterName}>Восстановление энергии</p>
              <p className={styles.boosterLevel}>{energyRecoveryLevel} ур.</p>
              <p className={styles.boosterEffect}>
                +{(energyRecoveryLevel * 0.33).toFixed(2)} энергии в секунду
              </p>
            </div>
            <div className={styles.boosterButtonContainer}>
              <button
                className={styles.applyButton}
                onClick={() => handleApplyBooster('energy_recovery')}
                disabled={
                  (cooldowns['energy_recovery'] && Date.now() < cooldowns['energy_recovery']) ||
                  user.coins < (nextUpgradeCosts['energy_recovery'] || 30)
                }
              >
                {nextUpgradeCosts['energy_recovery']
                  ? `${nextUpgradeCosts['energy_recovery']} монет`
                  : '30 монет'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boosters;

