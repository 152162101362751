import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Buttons.module.css";
import { useUser } from "../context/UserContext";

const Buttons = ({ activePage, onButtonClick }) => {
  const { state: user } = useUser();

  const userId = user?.data?.telegram_id;

  return (
    <div className={styles.buttonsContainer}>
      <button
        className={`${styles.button} ${
          activePage === "Main" ? styles.activeButton : ""
        }`}
        onClick={() => onButtonClick("Main")}
      >
        <Link to="/" className={styles.link}>
          <i className="fas fa-home"></i>
          Главная
        </Link>
      </button>
      <button
        className={`${styles.button} ${
          activePage === "Mining" ? styles.activeButton : ""
        }`}
        onClick={() => onButtonClick("Mining")}
      >
        <Link to={`/mining/${userId}`} className={styles.link}>
          <i className="fas fa-bolt"></i>
          Заработок
        </Link>
      </button>
      <button
        className={`${styles.button} ${
          activePage === "Friends" ? styles.activeButton : ""
        }`}
        onClick={() => onButtonClick("Friends")}
      >
        <Link to={`/friends/${userId}`} className={styles.link}>
          <i className="fas fa-users"></i>
          Друзья
        </Link>
      </button>
      <div className={`${styles.button} ${styles.disabledButton}`}>
        <i className="fas fa-gamepad"></i>
        Games
      </div>
    </div>
  );
};

export default Buttons;
