import React, { useState, useEffect } from "react";
import styles from "../styles/Daily.module.css";
import { useUser } from "../context/UserContext";
import TabNavigation from "./TabNavigation";
import pawnImage from "../images/pawn.png";
import knightImage from "../images/knight.png";
import bishopImage from "../images/bishop.png";
import rookImage from "../images/rook.png";
import queenImage from "../images/queen.png";
import axios from "axios";

const levelImages = [
  pawnImage,
  knightImage,
  bishopImage,
  rookImage,
  queenImage,
];

const Daily = () => {
  const { state: user, dispatch } = useUser();
  const [activeDay, setActiveDay] = useState(() => {
    const savedActiveDay = localStorage.getItem("activeDay");
    return savedActiveDay ? parseInt(savedActiveDay, 10) : 0;
  });
  const [collectedDays, setCollectedDays] = useState(() => {
    const savedCollectedDays = localStorage.getItem("collectedDays");
    return savedCollectedDays ? JSON.parse(savedCollectedDays) : [];
  });
  const [timer, setTimer] = useState("00:00:00");
  const [isCollectButtonDisabled, setIsCollectButtonDisabled] = useState(() => {
    const savedCollectButtonState = localStorage.getItem(
      "isCollectButtonDisabled"
    );
    return savedCollectButtonState
      ? JSON.parse(savedCollectButtonState)
      : false;
  });
  const [errorMessage, setErrorMessage] = useState("");
  const userId = user?.data?.telegram_id;

  // Обновление таймера и логики активации награды
  useEffect(() => {
    const fetchLastRewardTime = async () => {
      try {
        const response = await axios.get(`https://smetanavv.fvds.ru/api/user/${userId}`);
        const lastReward = response.data.last_daily_reward ? new Date(response.data.last_daily_reward) : null;
        const now = new Date();
        let diff = 0;
        if (lastReward) {
          diff = 24 * 60 * 60 * 1000 - (now - lastReward);
        } else {
          diff = 0;
        }

        if (diff > 0) {
          setIsCollectButtonDisabled(true);
          const timerInterval = setInterval(() => {
            const now = new Date();
            const nextDay = new Date(lastReward.getTime() + 24 * 60 * 60 * 1000);
            const diff = nextDay - now;

            if (diff <= 0) {
              setIsCollectButtonDisabled(false);
              clearInterval(timerInterval);
              setTimer("00:00:00");
            } else {
              const hours = String(Math.floor(diff / (1000 * 60 * 60))).padStart(2, "0");
              const minutes = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(2, "0");
              const seconds = String(Math.floor((diff / 1000) % 60)).padStart(2, "0");

              setTimer(`${hours}:${minutes}:${seconds}`);
            }
          }, 1000);

          return () => clearInterval(timerInterval);
        } else {
          setIsCollectButtonDisabled(false);
          setTimer("00:00:00");
        }
      } catch (error) {
        console.error("Ошибка при получении времени последней награды:", error);
      }
    };

    if (userId) {
      fetchLastRewardTime();
    }
  }, [userId]);

  // Функция для получения награды
  const handleCollect = async () => {
    if (isCollectButtonDisabled) return;

    try {
      const response = await axios.post("https://smetanavv.fvds.ru/api/daily-reward", {
        telegramId: userId,
      });

      if (response.data.success) {
        const newCoins = response.data.coins;
        setCollectedDays([...collectedDays, activeDay]);
        localStorage.setItem("collectedDays", JSON.stringify([...collectedDays, activeDay]));
        setActiveDay(activeDay + 1);
        localStorage.setItem("activeDay", activeDay + 1);
        setIsCollectButtonDisabled(true);
        localStorage.setItem("isCollectButtonDisabled", true);
        // Обновляем пользователя в контексте
        dispatch({ type: "SET_USER", payload: { ...user.data, coins: newCoins } });
        setErrorMessage("");
        // Установите таймер на 24 часа
        const lastReward = new Date();
        const nextDay = new Date(lastReward.getTime() + 24 * 60 * 60 * 1000);
        const timerInterval = setInterval(() => {
          const now = new Date();
          const diff = nextDay - now;

          if (diff <= 0) {
            setIsCollectButtonDisabled(false);
            clearInterval(timerInterval);
            setTimer("00:00:00");
          } else {
            const hours = String(Math.floor(diff / (1000 * 60 * 60))).padStart(2, "0");
            const minutes = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(2, "0");
            const seconds = String(Math.floor((diff / 1000) % 60)).padStart(2, "0");

            setTimer(`${hours}:${minutes}:${seconds}`);
          }
        }, 1000);
      } else {
        setErrorMessage(response.data.message || "Ошибка при получении награды.");
      }
    } catch (error) {
      console.error("Ошибка при получении награды:", error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Произошла ошибка. Попробуйте позже.");
      }
    }
  };

  return (
    <div className={styles.outerContainer}>
      <TabNavigation />
      <div className={styles.header}>
        <h2>Заходи в игру каждый день и получай бонусы</h2>
      </div>
      <div className={styles.bonusGrid}>
        {[...Array(16).keys()].map((day) => (
          <div
            key={day}
            className={`${styles.bonusCard} ${
              activeDay === day ? styles.activeDay : ""
            } ${collectedDays.includes(day) ? styles.collectedDay : ""}`}
          >
            <div className={styles.bonusImage}>
              <img
                src={levelImages[day % levelImages.length]}
                alt={`Day ${day + 1}`}
              />
              {collectedDays.includes(day) && (
                <div className={styles.checkmark}>✓</div>
              )}
            </div>
            <div className={styles.bonusText}>
              <span>День {day + 1}</span>
              <span>{(day + 1) * 1000}K</span>
            </div>
          </div>
        ))}
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      <div className={styles.timerAndButton}>
        <button
          className={styles.collectButton}
          onClick={handleCollect}
          disabled={isCollectButtonDisabled}
        >
          {isCollectButtonDisabled ? timer : "Получить"}
        </button>
      </div>
    </div>
  );
};

export default Daily;
