import React, { useEffect, useState } from "react";
import styles from "../styles/EnergyDisplay.module.css";

const EnergyDisplay = ({ energy, maxEnergy }) => {

  return (
    <div className={styles.energyDisplay}>
      <div className={styles.energyIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M13 2L3 14h9v8l8-12h-9z" />
        </svg>
      </div>
      <p>
        {energy}/<span className={styles.maxEnergy}>{maxEnergy}</span>
      </p>
    </div>
  );
};

export default EnergyDisplay;
