import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../styles/Header.module.css";
import pawnImage from "../images/pawn.png";
import knightImage from "../images/knight.png";
import bishopImage from "../images/bishop.png";
import rookImage from "../images/rook.png";
import queenImage from "../images/queen.png";

const levelImages = [
  { level: 1, image: pawnImage },
  { level: 2, image: knightImage },
  { level: 3, image: bishopImage },
  { level: 4, image: rookImage },
  { level: 5, image: queenImage },
];

const calculateLevel = (earningsPerHour) => {
  if (earningsPerHour >= 3000) return 5;
  if (earningsPerHour >= 1000) return 4;
  if (earningsPerHour >= 500) return 3;
  if (earningsPerHour >= 200) return 2;
  if (earningsPerHour >= 100) return 1;
  return 1;
};

const Header = ({ username, earningsPerHour, telegramId }) => {
  const [level, setLevel] = useState(1);
  const [levelImage, setLevelImage] = useState(pawnImage);
  const location = useLocation();

  useEffect(() => {
    const currentLevel = calculateLevel(earningsPerHour);
    setLevel(currentLevel);
    const imageInfo = levelImages.find((img) => img.level === currentLevel);
    setLevelImage(imageInfo ? imageInfo.image : pawnImage);
  }, [earningsPerHour]);

  const headerClass = () => {
    if (location.pathname.includes("/friends")) {
      return styles.friendsHeader;
    } else if (
      location.pathname.includes("/mining") ||
      location.pathname.includes("/daily") ||
      location.pathname.includes("/boosters")
    ) {
      return styles.miningHeader;
    } else {
      return "";
    }
  };

  return (
    <header className={`${styles.header} ${headerClass()}`}>
      <div className={styles.userInfo}>
        <div className={styles.avatar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </svg>
        </div>
        <p>{username}</p>
      </div>
    </header>
  );
};

export default Header;
