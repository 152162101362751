import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../styles/TabNavigation.module.css";
import { useUser } from "../context/UserContext";

const TabNavigation = () => {
  const { state: user } = useUser();
  const userId = user?.data?.telegram_id;


  return (
    <div className={styles.tabContainer}>
      <NavLink
        to={`/mining/${userId}`}
        className={({ isActive }) => (isActive ? styles.activeTab : styles.tab)}
      >
        Майнинг
      </NavLink>
      <NavLink
        to={`/daily/${userId}`}
        className={({ isActive }) => (isActive ? styles.activeTab : styles.tab)}
      >
        Ежедневные
      </NavLink>
      <NavLink
        to={`/boosters/${userId}`}
        className={({ isActive }) => (isActive ? styles.activeTab : styles.tab)}
      >
        Бустеры
      </NavLink>
    </div>
  );
};

export default TabNavigation;
