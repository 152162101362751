import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';
import styles from '../styles/CoinDisplay.module.css';
import pawnImage from '../images/pawn.png';
import knightImage from '../images/knight.png';
import bishopImage from '../images/bishop.png';
import rookImage from '../images/rook.png';
import queenImage from '../images/queen.png';

const levelImages = [
  { level: 1, image: pawnImage },
  { level: 2, image: knightImage },
  { level: 3, image: bishopImage },
  { level: 4, image: rookImage },
  { level: 5, image: queenImage },
];

const calculateLevel = (earningsPerHour) => {
  if (earningsPerHour >= 3000) return 5;
  if (earningsPerHour >= 1000) return 4;
  if (earningsPerHour >= 500) return 3;
  if (earningsPerHour >= 200) return 2;
  if (earningsPerHour >= 100) return 1;
  return 1;
};

const calculateTapValue = (level) => {
  switch (level) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    default:
      return 1;
  }
};

const CoinDisplay = ({ coins, onTap, energy }) => {
  const { state: { data: user } } = useUser();
  const [isShaking, setIsShaking] = useState(false);
  const [floatingNumbers, setFloatingNumbers] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [coinImage, setCoinImage] = useState(pawnImage);
  const [showYuhuu, setShowYuhuu] = useState(false);

  useEffect(() => {
    const level = calculateLevel(user?.earnings_per_hour);
    const imageInfo = levelImages.find((img) => img.level === level);
    setCoinImage(imageInfo ? imageInfo.image : pawnImage);
  }, [user?.earnings_per_hour]);

  const handleClick = (event) => {
    const level = calculateLevel(user?.earnings_per_hour);
    const tapValue = calculateTapValue(level);

    if (energy < tapValue) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    const newFloatingNumber = {
      id: Date.now(),
      value: clickCount % 5 === 0 ? '' : + `${tapValue}`,
      top: clickY,
      left: clickX,
      isLightning: clickCount % 5 === 0,
    };

    setFloatingNumbers((prev) => [...prev, newFloatingNumber]);

    setIsShaking(true);
    setClickCount((prevCount) => prevCount + 1);

    setTimeout(() => {
      setIsShaking(false);
    }, 500);

    setTimeout(() => {
      setFloatingNumbers((prev) => prev.filter((num) => num.id !== newFloatingNumber.id));
    }, 1000);

    if (clickCount % 5 === 0) {
      setShowYuhuu(true);
      setTimeout(() => {
        setShowYuhuu(false);
      }, 1500); 
    }
    
    onTap();
  };

  return (
    <div className={styles.coinContainer}>
      <div className={`${styles.coinDisplay} ${isShaking ? styles.shake : ''}`} onClick={handleClick}>
        <div className={styles.coinImageContainer}>
          <img src={coinImage} alt="Coins" />
          {floatingNumbers.map((num) => (
            <div
              key={num.id}
              className={`${styles.floatUp} ${num.isLightning ? styles.lightning : ''}`}
              style={{ top: `${num.top}px`, left: `${num.left}px` }}
            >
              {num.value}
            </div>
          ))}
        </div>
      </div>
      {showYuhuu && (
        <div className={`${styles.yuhuuBubble} ${showYuhuu ? styles.show : styles.hide}`}>
          Пешки не орешки!
        </div>
      )}
    </div>
  );
};

export default CoinDisplay;
