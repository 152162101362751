import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../store';
import { useUser } from '../context/UserContext';

const useAuth = () => {
  const dispatch = useDispatch();
  const { state: userState, dispatch: userDispatch } = useUser();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); 

  const handleAuth = useCallback(async () => {
    try {
      const telegramData = window.Telegram.WebApp.initDataUnsafe;

      if (!telegramData || !telegramData.user) {
        console.error("No Telegram data available");
        setLoading(false); // Завершаем загрузку в случае ошибки
        return;
      }

      const telegramId = telegramData.user.id;
      const telegramUsername = telegramData.user.username || "TestUser";

      const storedUser = JSON.parse(localStorage.getItem('user'));
      const storedTimestamp = localStorage.getItem('user_timestamp');

      const now = new Date().getTime();
      const shouldUpdateUser = !storedUser || !storedTimestamp || (now - parseInt(storedTimestamp, 10)) > 86400000; // 1 день в миллисекундах

      if (storedUser && storedUser.telegram_id === telegramId && !shouldUpdateUser) {
        userDispatch({ type: "SET_USER", payload: storedUser });
        dispatch(setUser(storedUser));
        setIsAuthenticated(true);
        setLoading(false); // Завершаем загрузку
        return;
      }

      const response = await fetch(`https://smetanavv.fvds.ru/api/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          telegramId: telegramId,
          username: telegramUsername,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const userData = await response.json();
      userDispatch({ type: "SET_USER", payload: userData.user });
      dispatch(setUser(userData.user));

      localStorage.setItem('user', JSON.stringify(userData.user));
      localStorage.setItem('user_timestamp', now.toString());

      setIsAuthenticated(true);
    } catch (error) {
      console.error("Authentication failed:", error.message);
      setIsAuthenticated(false);
    } finally {
      setLoading(false); // Завершаем загрузку в любом случае
    }
  }, [dispatch, userDispatch]);

  useEffect(() => {
    if (!isAuthenticated && loading) {
      handleAuth();
    }
  }, [isAuthenticated, loading, handleAuth]);

  return { isAuthenticated, loading };
};

export default useAuth;
