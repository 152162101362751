import { createSlice, configureStore } from '@reduxjs/toolkit';

// Функция для безопасного парсинга JSON
const safeJSONParse = (item) => {
  try {
    return JSON.parse(item);
  } catch (error) {
    console.error('Ошибка при парсинге JSON:', error);
    return null;
  }
};

const initialUserData = safeJSONParse(localStorage.getItem('user')) || { telegram_id: '', username: '', coins: 0, energy: 0, earnings_per_hour: 0, level: 1, tasks: [] };
const initialTasksData = safeJSONParse(localStorage.getItem('tasks')) || [];

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: initialUserData,
    tasks: initialTasksData,
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
      localStorage.setItem('user', JSON.stringify(state.data));
    },
    setUserTasks: (state, action) => {
      state.tasks = action.payload;
      state.data.tasks = action.payload;
      localStorage.setItem('user', JSON.stringify(state.data));

      console.log(`{state.tasks}`);

      localStorage.setItem('tasks', JSON.stringify(state.tasks));
    },
    setUserCoins: (state, action) => {
      state.data.coins = action.payload;
      localStorage.setItem('user', JSON.stringify(state.data));
    },
    setUserEnergy: (state, action) => {
      state.data.energy = action.payload;
      localStorage.setItem('user', JSON.stringify(state.data));
    },
    setUserLevel: (state, action) => {
      state.data.level = action.payload;
      localStorage.setItem('user', JSON.stringify(state.data));
    },
  },
});

export const { setUser, setUserTasks, setUserCoins, setUserEnergy, setUserLevel } = userSlice.actions;

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
