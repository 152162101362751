import React, { createContext, useContext, useReducer, useEffect, useRef } from "react";
import axios from "axios";

const safeJSONParse = (item) => {
  try {
    return JSON.parse(item);
  } catch (error) {
    return null;
  }
};

const initialUserData = safeJSONParse(localStorage.getItem("user")) || null;
const initialTasksData = safeJSONParse(localStorage.getItem("tasks")) || [];

const UserContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, data: action.payload };
    case "SET_USER_TASKS":
      const updatedState = { ...state, tasks: action.payload };
      localStorage.setItem("tasks", JSON.stringify(updatedState.tasks));
      return updatedState;
    case "SET_USER_COINS":
      const updatedUserWithCoins = { ...state.data, coins: action.payload };
      localStorage.setItem("user", JSON.stringify(updatedUserWithCoins));
      return { ...state, data: updatedUserWithCoins };
    case "SET_USER_ENERGY":
      const updatedUserWithEnergy = { ...state.data, energy: action.payload };
      localStorage.setItem("user", JSON.stringify(updatedUserWithEnergy));
      return { ...state, data: updatedUserWithEnergy };
    case "INCREASE_REGEN":
      const updatedUserWithRegen = { ...state.data, energy_regen: (state.data.energy_regen || 0) + action.payload };
      localStorage.setItem("user", JSON.stringify(updatedUserWithRegen));
      return { ...state, data: updatedUserWithRegen };
    default:
      return state;
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, {
    data: initialUserData,
    tasks: initialTasksData,
  });

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  // Логика регенерации энергии
  useEffect(() => {
    if (!state.data) return;

    const energyInterval = setInterval(() => {
      if (stateRef.current.data.energy < stateRef.current.data.max_energy) {
        const updatedEnergy = Math.min(stateRef.current.data.energy + 1, stateRef.current.data.max_energy);
        const updatedUser = { ...stateRef.current.data, energy: updatedEnergy };
        dispatch({ type: "SET_USER", payload: updatedUser });
        localStorage.setItem("user", JSON.stringify(updatedUser));
        console.log(`Energy regenerated: ${updatedEnergy}/${stateRef.current.data.max_energy}`);
      }
    }, 1000);

    return () => clearInterval(energyInterval);
  }, [state.data.max_energy, dispatch]);

  // Логика обновления данных на сервере
  useEffect(() => {
    if (!state.data || !state.data.telegram_id) return;

    const saveToDatabase = setInterval(async () => {
      try {
        await axios.post("https://smetanavv.fvds.ru/api/update-coins", {
          telegramId: state.data.telegram_id,
          coins: Math.floor(state.data.coins),
          energy: state.data.energy,
        });
      } catch (error) {
        console.error("Ошибка при обновлении данных на сервере:", error);
      }
    }, 5000);

    return () => {
      clearInterval(saveToDatabase);
    };
  }, [state.data]);

  // Сохранение состояния в localStorage при изменении данных
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.data));
  }, [state.data]);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
