import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import styles from "../styles/Mining.module.css";
import axios from "axios";
import TabNavigation from "./TabNavigation";
import { useUser } from "../context/UserContext";

const maxLevel = 30;

const Mining = () => {
  const { userId } = useParams();
  const { state: { data: user }, dispatch } = useUser();
  const [cards, setCards] = useState([]);
  const userRef = useRef(user);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    const fetchUserAndCards = async () => {
      try {
        const { data: userData } = await axios.get(`https://smetanavv.fvds.ru/user/${userId}`);
        if (userData) {
          dispatch({ type: "SET_USER", payload: userData });
          localStorage.setItem("user", JSON.stringify(userData));
        }

        const userCards = userData.userCards
          .map((uc) => ({
            ...uc.card,
            level: uc.level,
          }))
          .sort((a, b) => a.id - b.id);

        setCards(userCards);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchUserAndCards();
  }, [userId, dispatch]);

const handleUpgrade = async (cardId) => {
    const card = cards.find((c) => c.id === cardId);
    if (!card || card.level >= maxLevel) {
        console.error("Условия для улучшения не выполнены");
        return;
    }

    const upgradeCost = card.upgradeCost || 0;
    const cost = upgradeCost + card.level * upgradeCost;

    if (isNaN(cost)) {
        console.error("Стоимость улучшения не является числом");
        return;
    }

    const requestBody = {
        telegramId: user?.telegram_id,
        cardId,
    };

    try {
        const response = await axios.post("https://smetanavv.fvds.ru/api/upgrade", requestBody);

        if (!response.data.success) {
            throw new Error(response.data.message);
        }

        const updatedUser = response.data.user;

        // Обновляем карточки на основе данных с сервера
        const updatedCards = updatedUser.userCards.map((uc) => ({
            ...uc.card,
            level: uc.level,
        }));

        updatedCards.sort((a, b) => a.id - b.id);

        // Обновляем состояние пользователя
        dispatch({ type: "SET_USER", payload: updatedUser });
        localStorage.setItem("user", JSON.stringify(updatedUser));

        // Обновляем состояние карточек
        setCards(updatedCards);

        console.log(`Уровень пользователя обновлен: ${updatedUser.level}`);
    } catch (error) {
        console.error("Ошибка при улучшении: ", error.message);
    }
};


  useEffect(() => {
    let lastUpdateTime = Date.now();

    const updateCoins = () => {
      const earningsPerHour = Number(userRef.current?.earnings_per_hour) || 0;
      const coins = Number(userRef.current?.coins) || 0;

      const currentTime = Date.now();
      const elapsedSeconds = (currentTime - lastUpdateTime) / 1000;
      lastUpdateTime = currentTime;

      const earnings = (earningsPerHour / 3600) * elapsedSeconds;
      const updatedCoins = coins + earnings;

      if (!isNaN(updatedCoins)) {
        const updatedUser = {
          ...userRef.current,
          coins: updatedCoins,
        };
        dispatch({ type: "SET_USER", payload: updatedUser });
        localStorage.setItem("user", JSON.stringify(updatedUser));
      } else {
        console.error('Обновленные монеты не являются числом', { updatedCoins, earnings, coins });
      }
    };

    const intervalId = setInterval(updateCoins, 1000);

    const saveToDatabase = setInterval(async () => {
      try {
        await axios.post("https://smetanavv.fvds.ru/api/update-coins", {
          telegramId: userRef.current?.telegram_id,
          coins: userRef.current?.coins,
        });
      } catch (error) {
        console.error("Ошибка при обновлении данных на сервере:", error);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearInterval(saveToDatabase);
    };
  }, [dispatch]);

  return (
    <div className={styles.outerContainer}>
      <TabNavigation />
      <div className={styles.miningContainer}>
        {Array.isArray(cards) && cards.length > 0 ? (
          cards.map((card) => (
            <div key={card.id} className={styles.taskCard}>
              <div className={styles.taskHeader}>
                <div className={styles.taskImage}>
                  <img src={`https://smetanavv.fvds.ru/uploads/${card.image}`} alt={card.name} />
                </div>
                <div className={styles.taskLevel}>{card.level} ур.</div>
              </div>
              <div className={styles.taskDetails}>
                <p className={styles.taskName}>{card.name}</p>
                <p className={styles.cardDescription}>{card.description}</p>
                <p className={styles.earningsPerHour}>
                  Доход в час: {card.earningsPerHour * card.level}
                </p>
                <button
                  onClick={() => handleUpgrade(card.id)}
                  disabled={
                    card.level >= maxLevel ||
                    user.coins < card.upgradeCost + card.level * card.upgradeCost
                  }
                  className={
                    card.level >= maxLevel ||
                    user.coins < card.upgradeCost + card.level * card.upgradeCost
                      ? styles.upgradeButtonDisabled
                      : styles.upgradeButton
                  }
                >
                  {card.upgradeCost + card.level * card.upgradeCost} монет
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Нет карточек для отображения.</p>
        )}
      </div>
    </div>
  );
};

export default Mining;
