import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../context/UserContext";
import styles from "../styles/Friends.module.css";

const Friends = () => {
  const { state: user } = useUser();
  const userId = user?.data?.telegram_id;
  const [invitedFriends, setInvitedFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInvitedFriends = async () => {
      try {
        const { data } = await axios.get(
          `https://smetanavv.fvds.ru/api/invited-friends/${userId}`
        );
        setInvitedFriends(data);
      } catch (error) {
        console.error("Ошибка при получении списка друзей:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchInvitedFriends();
    }
  }, [userId]);

  const handleInviteClick = () => {
    const telegramInviteLink = `https://t.me/PawnsTokenTap_bot?start=referral_${userId}`;

    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.MainButton.hide();

      try {
        window.Telegram.WebApp.switchInlineQuery(`Привет! Присоединяйся к игре: ${telegramInviteLink}`);
      } catch (error) {
        console.error("Ошибка при попытке вставить инлайн-запрос:", error);
      }
    } else {
      window.open(telegramInviteLink, "_blank");
    }
  };

  const handleCopyClick = () => {
    const referralLink = `https://t.me/PawnsTokenTap_bot?start=referral_${userId}`;
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        alert("Реферальная ссылка скопирована!");
      })
      .catch((err) => {
        console.error("Ошибка при копировании: ", err);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleSection}>
        <div className={styles.title}>Приглашай друзей</div>
        <div className={styles.subTitle}>Ты получишь 20% от их дохода</div>
      </div>
      <div className={styles.inviteSection}>
        <div className={styles.inviteContainer}>
          <div className={styles.giftIcon}>🎁</div>
          <p className={styles.inviteButton} onClick={handleInviteClick}>
            Пригласить друга
          </p>
          <div className={styles.bonusText}>
            +50 000 для тебя и твоего друга
          </div>
        </div>
      </div>
      <div className={styles.friendsListSection}>
        <div className={styles.friendsListHeader}>
          Список ваших друзей <span className={styles.friendCount}>{invitedFriends.length}</span>
        </div>
        <div className={styles.friendsList}>
          {invitedFriends.length > 0 ? (
            invitedFriends.map((friend, index) => (
              <div key={friend.telegram_id} className={styles.friendCard}>
                <div className={styles.friendName}>
                  {index + 1}. {friend.username || (friend.first_name && friend.last_name ? `${friend.first_name} ${friend.last_name}` : 'Без имени')}
                </div>
                <div className={styles.friendEarnings}>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noFriends}>Список друзей пуст</div>
          )}

        </div>
      </div>
      <div className={styles.bottomNav}>
        <button className={styles.bottomNavButton} onClick={handleInviteClick}>
          Пригласить друга
        </button>
        <button className={styles.copyIcon} onClick={handleCopyClick}>
          <i className="fas fa-copy"></i>
        </button>
      </div>
    </div>
  );
};

export default Friends;
