// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  bottom: 15px;
  height: 40px;
  left: 15px;
  right: 15px;
  background: rgba(46, 46, 46, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
  position: fixed;
  border-radius: 15px;
  user-select: none; 

}

.button {
  flex: 1 1;
  padding: 10px;
  height: 40px;
  margin: 0 5px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.3s, background-color 0.3s;
}

.button.activeButton {
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
  height: 50px;

}

.button i {
  font-size: 18px;
  margin-bottom: 5px;
}

.link {
  text-decoration: none;
  color: inherit;
}

.disabledButton {
  cursor: not-allowed;
  opacity: 0.3; 
  pointer-events: none; 
}
`, "",{"version":3,"sources":["webpack://./src/styles/Buttons.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,WAAW;EACX,iCAAiC;EACjC,mCAAmC;EACnC,2BAA2B;EAC3B,0CAA0C;EAC1C,UAAU;EACV,eAAe;EACf,mBAAmB;EACnB,iBAAiB;;AAEnB;;AAEA;EACE,SAAS;EACT,aAAa;EACb,YAAY;EACZ,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,+CAA+C;AACjD;;AAEA;EACE,0CAA0C;EAC1C,UAAU;EACV,YAAY;;AAEd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".buttonsContainer {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding: 10px;\n  bottom: 15px;\n  height: 40px;\n  left: 15px;\n  right: 15px;\n  background: rgba(46, 46, 46, 0.6);\n  -webkit-backdrop-filter: blur(10px);\n  backdrop-filter: blur(10px);\n  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);\n  z-index: 1;\n  position: fixed;\n  border-radius: 15px;\n  user-select: none; \n\n}\n\n.button {\n  flex: 1 1;\n  padding: 10px;\n  height: 40px;\n  margin: 0 5px;\n  font-size: 16px;\n  background-color: transparent;\n  color: white;\n  border: none;\n  border-radius: 10px;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  opacity: 0.7;\n  transition: opacity 0.3s, background-color 0.3s;\n}\n\n.button.activeButton {\n  background-color: rgba(255, 255, 255, 0.2);\n  opacity: 1;\n  height: 50px;\n\n}\n\n.button i {\n  font-size: 18px;\n  margin-bottom: 5px;\n}\n\n.link {\n  text-decoration: none;\n  color: inherit;\n}\n\n.disabledButton {\n  cursor: not-allowed;\n  opacity: 0.3; \n  pointer-events: none; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `buttonsContainer`,
	"button": `button`,
	"activeButton": `activeButton`,
	"link": `link`,
	"disabledButton": `disabledButton`
};
export default ___CSS_LOADER_EXPORT___;
