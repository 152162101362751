import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import store from './store';
import { UserProvider, useUser } from "./context/UserContext";
import "./styles/App.css";
import useAuth from "./hooks/useAuth";
import useSocket from "./hooks/useSocket";
import axios from "axios";

import Header from "./components/Header";
import MainDisplay from "./components/MainDisplay";
import CoinDisplay from "./components/CoinDisplay";
import EnergyDisplay from "./components/EnergyDisplay";
import Buttons from "./components/Buttons";
import Mining from "./components/Mining";
import Daily from "./components/Daily";
import Friends from "./components/Friends";
import LoadingPage from "./components/LoadingPage";
import Boosters from './components/Boosters';

export const calculateMaxEnergy = (energyLevel) => {
  return 1000 + energyLevel * 500;
};

export const calculateTapValue = (level) => {
  switch (level) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    default:
      return 1;
  }
};

export const calculateLevel = (earningsPerHour) => {
  if (earningsPerHour >= 3000) return 5;
  if (earningsPerHour >= 1000) return 4;
  if (earningsPerHour >= 500) return 3;
  if (earningsPerHour >= 200) return 2;
  if (earningsPerHour >= 100) return 1;
  return 1;
};


const AppContent = () => {
  const { state: user, dispatch } = useUser();
  const { tap } = useSocket();
  const { isAuthenticated, loading } = useAuth();
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingPage(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleTap = () => {
    const level = calculateLevel(user.data.earnings_per_hour);
    const tapValue = calculateTapValue(level);

    if (user.data.energy < tapValue) return;

    // Обновляем монеты и энергию пользователя
    const updatedCoins = (parseFloat(user.data.coins) || 0) + tapValue;
    const updatedEnergy = Math.max(0, user.data.energy - tapValue);

    const updatedUser = {
      ...user.data,
      coins: updatedCoins,
      energy: updatedEnergy,
      earnings_per_hour: user.data.earnings_per_hour,
    };

    // Обновляем состояние пользователя
    dispatch({ type: "SET_USER", payload: updatedUser });
    localStorage.setItem("user", JSON.stringify(updatedUser));

    // Отправка на сервер через сокет
    tap();
  };



  const onButtonClick = (page) => {
    const updatedUser = { ...user.data, activePage: page };
    dispatch({ type: "SET_USER", payload: updatedUser });
  };

  const maxEnergy = user.data.max_energy || 1000;



  if (loading) {
    return <div>Authenticating...</div>;
  }

  if (!isAuthenticated) {
    return <div>Authentication failed</div>;
  }

  if (!user.data) {
    return <div>Loading user data...</div>;
  }

  const level = calculateLevel(user.data.earnings_per_hour);

  if (isLoadingPage) {
    return <LoadingPage />;
  }

  return (
    <div className="app">
      <Header
        username={user.data.username || "Guest"}
        coins={user.data.coins}
        earningsPerHour={user.data.earnings_per_hour || 0}
        telegramId={user.data.telegram_id}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <MainDisplay
                energy={user.data.energy || "0"}
                maxEnergy={maxEnergy}
                coinsPerTap={calculateTapValue(level)}
                coinsPerHour={user.data.earnings_per_hour || 0}
              />
              <CoinDisplay
                coins={user.data.coins}
                onTap={handleTap}
                energy={user.data.energy}
              />
              <EnergyDisplay
                energy={user.data.energy || "0"}
		maxEnergy={user.data.max_energy}

              />
            </>
          }
        />
        <Route path="/mining/:userId" element={<Mining />} />
        <Route path="/daily/:userId" element={<Daily />} />

        <Route
          path="/boosters/:userId"
          element={
            <div style={{ backgroundColor: '#1C1C1E', minHeight: '100vh' }}>
              <Boosters />
            </div>
          }
        />

        <Route path="/friends/:userId" element={<Friends />} />
      </Routes>
      <Buttons
        activePage={user.data.activePage}
        onButtonClick={onButtonClick}
      />
    </div>
  );
};

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={new QueryClient()}>
      <UserProvider>
        <Router>
          <AppContent />
        </Router>
      </UserProvider>
    </QueryClientProvider>
  </Provider>
);

export default App;
