// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #313132ad;
    padding: 10px;
    width: 85%;
    border-radius: 15px;
    height: 30px;
    margin: 0 auto 20px auto;
  }
  
  .tab {
    color: #ccc;
    text-decoration: none; 
    padding: 10px 20px;
    border-radius: 10px;
    margin: 0 5px;
    transition: background-color 0.3s, color 0.3s;
    outline: none;
  }
  
  .activeTab {
    background-color: #444;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;

    border: 1px solid #555; 
    text-decoration: none;
  }
  
  .tab:hover {
    background-color: #444;
    color: #fff;
  }
  
  .tabContainer .tab, .tabContainer .activeTab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-decoration: none; 
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/TabNavigation.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,YAAY;IACZ,wBAAwB;EAC1B;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,6CAA6C;IAC7C,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;;IAEnB,sBAAsB;IACtB,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,qBAAqB;EACvB","sourcesContent":[".tabContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #313132ad;\n    padding: 10px;\n    width: 85%;\n    border-radius: 15px;\n    height: 30px;\n    margin: 0 auto 20px auto;\n  }\n  \n  .tab {\n    color: #ccc;\n    text-decoration: none; \n    padding: 10px 20px;\n    border-radius: 10px;\n    margin: 0 5px;\n    transition: background-color 0.3s, color 0.3s;\n    outline: none;\n  }\n  \n  .activeTab {\n    background-color: #444;\n    color: #fff;\n    padding: 10px 20px;\n    border-radius: 10px;\n\n    border: 1px solid #555; \n    text-decoration: none;\n  }\n  \n  .tab:hover {\n    background-color: #444;\n    color: #fff;\n  }\n  \n  .tabContainer .tab, .tabContainer .activeTab {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    text-decoration: none; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `tabContainer`,
	"tab": `tab`,
	"activeTab": `activeTab`
};
export default ___CSS_LOADER_EXPORT___;
