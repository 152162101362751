import React from "react";
import { useUser } from "../context/UserContext";
import styles from "../styles/MainDisplay.module.css";
import pawnImage from "../images/pawn.png";
import knightImage from "../images/knight.png";
import bishopImage from "../images/bishop.png";
import rookImage from "../images/rook.png";
import queenImage from "../images/queen.png";

const levelImages = [
  { level: 1, image: pawnImage },
  { level: 2, image: knightImage },
  { level: 3, image: bishopImage },
  { level: 4, image: rookImage },
  { level: 5, image: queenImage },
];

const calculateLevel = (earningsPerHour) => {
  if (earningsPerHour >= 3000) return 5;
  if (earningsPerHour >= 1000) return 4;
  if (earningsPerHour >= 500) return 3;
  if (earningsPerHour >= 200) return 2;
  if (earningsPerHour >= 100) return 1;
  return 1;
};

const calculateTapValue = (level) => {
  switch (level) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    default:
      return 1;
  }
};

const MainDisplay = () => {
  const { state: { data: user } } = useUser();
  const level = calculateLevel(user?.earnings_per_hour);
  const tapValue = calculateTapValue(level);
  const levelImage =
    levelImages.find((img) => img.level === level)?.image || pawnImage;

  const displayCoins = isNaN(user?.coins)
    ? "0"
    : Math.floor(user.coins).toString();

  // Добавьте лог для проверки
  console.log('User coins in MainDisplay:', displayCoins);

  return (
    <div className={styles.mainDisplay}>
      <div className={styles.coinsContainer}>
        <div className={styles.levelContainer}>
          <img
            src={levelImage}
            alt={`Level ${level}`}
            className={styles.levelImage}
          />
        </div>
        <div className={styles.coinsInfo}>
          <p className={styles.coinsValue}>{displayCoins}</p>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.infoRow}>
          <p className={styles.tapValue}>{user?.earnings_per_hour || 0}</p>
          <p className={styles.translucentText}>Доход / час</p>
        </div>
        <div className={styles.infoRow}>
          <p className={styles.tapValue}>{tapValue}</p>
          <p className={styles.translucentText}>Доход / тап</p>
        </div>
      </div>
    </div>
  );
};

export default MainDisplay;
