import React, { useState, useEffect } from "react";
import styles from "../styles/LoadingPage.module.css";

const LoadingPage = () => {
  const [loading, setLoading] = useState(true); 
  const [loadingSteps, setLoadingSteps] = useState([
    { name: "Basic game", completed: true },
    { name: "Mining", completed: true },
    { name: "Referral", completed: true },
    { name: "Daily rewards", completed: true },
    { name: "Boosts", completed: false },
    { name: "Customization", completed: false, next: true },
    { name: "Boss fight", completed: false, soon: true },
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className={styles.container}>
        <h2 className={styles.title}>Roadmap</h2>
        <ul className={styles.roadmapList}>
          {loadingSteps.map((step, index) => (
            <li key={index} className={styles.roadmapItem}>
              <span className={styles.stepName}>{step.name}</span>
              {step.completed && <span className={styles.checkmark}>✓</span>}
              {step.next && <span className={styles.next}>Next</span>}
              {step.soon && <span className={styles.soon}>Soon</span>}
            </li>
          ))}
        </ul>
        <div className={styles.spinner}>⏳</div>
      </div>
    );
  }

  return (
    <div>
      <h1>Приложение загружено!</h1>
    </div>
  );
};

export default LoadingPage;
