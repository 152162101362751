// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none; 
}

.miningHeader {
  background-color: #1d1e21;
}

.friendsHeader {
  background-color: #141414;
}

.boosterHeader {
  background-color: #141414;
}

.userInfo {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.avatar svg {
  width: 24px;
  height: 24px;
  color: #fff;
}

.userInfo p {
  margin: 0;
  font-size: 16px;
}

.coinsInfo {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 16px;
}

.coinsInfo img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.levelImage {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.centerText {
  text-align: center;
  flex: 1;
}

.centerText img {
  width: 40px;
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,OAAO;AACT;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".header {\n  padding: 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  user-select: none; \n}\n\n.miningHeader {\n  background-color: #1d1e21;\n}\n\n.friendsHeader {\n  background-color: #141414;\n}\n\n.boosterHeader {\n  background-color: #141414;\n}\n\n.userInfo {\n  display: flex;\n  align-items: center;\n}\n\n.avatar {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: #ccc;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 10px;\n}\n\n.avatar svg {\n  width: 24px;\n  height: 24px;\n  color: #fff;\n}\n\n.userInfo p {\n  margin: 0;\n  font-size: 16px;\n}\n\n.coinsInfo {\n  display: flex;\n  align-items: center;\n  margin: 0;\n  font-size: 16px;\n}\n\n.coinsInfo img {\n  width: 20px;\n  height: 20px;\n  margin-right: 5px;\n}\n\n.levelImage {\n  width: 40px;\n  height: 40px;\n  margin-right: 10px;\n}\n\n.centerText {\n  text-align: center;\n  flex: 1;\n}\n\n.centerText img {\n  width: 40px;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header`,
	"miningHeader": `miningHeader`,
	"friendsHeader": `friendsHeader`,
	"boosterHeader": `boosterHeader`,
	"userInfo": `userInfo`,
	"avatar": `avatar`,
	"coinsInfo": `coinsInfo`,
	"levelImage": `levelImage`,
	"centerText": `centerText`
};
export default ___CSS_LOADER_EXPORT___;
