// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.energyDisplay {
  position: fixed;
  bottom: 70px;
  left: 10px;
  display: flex;
  align-items: center;
  padding: 5px 5px;
  border-radius: 8px;
  color: #fff;
  user-select: none;

}

.energyIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.energyIcon svg {
  width: 100%;
  height: 100%;
  fill: #fff; 
}

.maxEnergy {
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/styles/EnergyDisplay.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,iBAAiB;;AAEnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,YAAY;AACd","sourcesContent":[".energyDisplay {\n  position: fixed;\n  bottom: 70px;\n  left: 10px;\n  display: flex;\n  align-items: center;\n  padding: 5px 5px;\n  border-radius: 8px;\n  color: #fff;\n  user-select: none;\n\n}\n\n.energyIcon {\n  width: 24px;\n  height: 24px;\n  margin-right: 10px;\n}\n\n.energyIcon svg {\n  width: 100%;\n  height: 100%;\n  fill: #fff; \n}\n\n.maxEnergy {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"energyDisplay": `energyDisplay`,
	"energyIcon": `energyIcon`,
	"maxEnergy": `maxEnergy`
};
export default ___CSS_LOADER_EXPORT___;
