import { useEffect, useCallback, useState, useRef } from "react";
import io from "socket.io-client";
import { useUser } from "../context/UserContext";

const useSocket = () => {
  const { state: user, dispatch } = useUser();
  const [tapCount, setTapCount] = useState(0);
  const timerRef = useRef(null);
  const socket = useRef(null);

  useEffect(() => {
    socket.current = io("https://smetanavv.fvds.ru", {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });

    socket.current.on("connect", () => {
      console.log("Connected to server");
      if (user?.telegram_id) {
        socket.current.emit("auth", { telegramId: user.telegram_id });
      }
    });
    
    socket.current.on("auth-success", (data) => {
      console.log("Authenticated with server");
    });

    socket.current.on("coinsUpdated", (data) => {
      const updatedUser = {
        ...user.data,
        coins: data.coins,
        energy: data.energy,
      };
      dispatch({ type: "SET_USER", payload: updatedUser });
      localStorage.setItem("user", JSON.stringify(updatedUser));
    });
    
    socket.current.on("energyUpdated", (data) => {
      // Обновляем только энергию, не трогаем монеты
      const updatedUser = {
        ...user.data,
        energy: data.energy,
      };
      dispatch({ type: "SET_USER", payload: updatedUser });
      localStorage.setItem("user", JSON.stringify(updatedUser));
    });

    socket.current.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    socket.current.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    socket.current.on("error", (err) => {
      console.error("Socket error:", err);
    });

    return () => {
      socket.current.disconnect();
    };
  }, [user?.telegram_id, dispatch]);

  const tap = useCallback(() => {
    setTapCount((prevTapCount) => prevTapCount + 1);
  }, []);

  useEffect(() => {
    if (tapCount > 0) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        socket.current.emit("tap", {
          telegramId: user?.telegram_id,
          count: tapCount,
        });
        setTapCount(0);
      }, 1000);
    }
  }, [tapCount, user?.telegram_id]);

  return { tap };
};

export default useSocket;
